import "./Social.css";

const Social = () => {
  return (
    <div className="social">
      <div className="social__item">
        <a
          href="https://www.instagram.com/mbankkgz/"
          target="_blank"
          rel="noreferrer"
          className="social__link"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3316 1.11115H6.66493C3.59715 1.11115 1.10938 3.59892 1.10938 6.6667V13.3334C1.10938 16.4011 3.59715 18.8889 6.66493 18.8889H13.3316C16.3994 18.8889 18.8872 16.4011 18.8872 13.3334V6.6667C18.8872 3.59892 16.3994 1.11115 13.3316 1.11115ZM17.2205 13.3334C17.2205 15.4778 15.476 17.2223 13.3316 17.2223H6.66493C4.52049 17.2223 2.77604 15.4778 2.77604 13.3334V6.6667C2.77604 4.52226 4.52049 2.77781 6.66493 2.77781H13.3316C15.476 2.77781 17.2205 4.52226 17.2205 6.6667V13.3334Z"
              fill="#0092A1"
            ></path>
            <path
              d="M10.0011 5.5556C7.54664 5.5556 5.55664 7.5456 5.55664 10C5.55664 12.4545 7.54664 14.4445 10.0011 14.4445C12.4555 14.4445 14.4455 12.4545 14.4455 10C14.4455 7.5456 12.4555 5.5556 10.0011 5.5556ZM10.0011 12.7778C8.46997 12.7778 7.22331 11.5312 7.22331 10C7.22331 8.46783 8.46997 7.22227 10.0011 7.22227C11.5322 7.22227 12.7789 8.46783 12.7789 10C12.7789 11.5312 11.5322 12.7778 10.0011 12.7778Z"
              fill="#0092A1"
            ></path>
            <path
              d="M14.9989 5.55557C15.3057 5.55557 15.5545 5.30684 15.5545 5.00001C15.5545 4.69319 15.3057 4.44446 14.9989 4.44446C14.6921 4.44446 14.4434 4.69319 14.4434 5.00001C14.4434 5.30684 14.6921 5.55557 14.9989 5.55557Z"
              fill="#0092A1"
            ></path>
          </svg>
        </a>
      </div>

      <div className="social__item">
        <a
          href="https://www.facebook.com/mbankkgz"
          target="_blank"
          rel="noreferrer"
          className=" social__link"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_6090_12436)">
              <path
                d="M14.9355 7.26562H11.6543V5.07812C11.6543 4.47437 12.1443 3.98437 12.748 3.98437H13.8418V1.25H11.6543C9.84195 1.25 8.37305 2.71891 8.37305 4.53125V7.26562H6.18555V10H8.37305V18.75H11.6543V10H13.8418L14.9355 7.26562Z"
                fill="#0092A1"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_6090_12436">
                <rect
                  width="17.5"
                  height="17.5"
                  fill="white"
                  transform="translate(0.833984 1.25)"
                ></rect>
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
      <div className="social__item">
        <a
          href="https://ok.ru/mbank"
          target="_blank"
          rel="noreferrer"
          className=" social__link"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0034 10.8232C12.9921 10.8232 15.415 8.40034 15.415 5.41158C15.415 2.42281 12.9921 0 10.0034 0C7.01461 0 4.5918 2.42285 4.5918 5.41162C4.59547 8.39882 7.01617 10.8195 10.0034 10.8232ZM10.0034 2.49766C11.6127 2.49766 12.9173 3.80226 12.9173 5.41162C12.9173 7.02097 11.6127 8.32557 10.0034 8.32557C8.39402 8.32557 7.08942 7.02097 7.08942 5.41162C7.08942 3.80226 8.39406 2.49766 10.0034 2.49766Z"
              fill="#0092A1"
            ></path>
            <path
              d="M15.303 13.3872C15.8911 12.9998 16.2454 12.343 16.2462 11.6388C16.2544 11.0543 15.9246 10.5177 15.3995 10.2609C14.86 9.99144 14.2142 10.0521 13.7344 10.4174C11.5106 12.0387 8.4944 12.0387 6.27058 10.4174C5.79001 10.054 5.14534 9.99343 4.60546 10.2609C4.0806 10.5176 3.75063 11.0537 3.75793 11.6379C3.75918 12.3421 4.11342 12.9986 4.70123 13.3863C5.45537 13.8878 6.27663 14.2802 7.14063 14.5519C7.28717 14.5974 7.43839 14.6396 7.59438 14.6785L5.07841 17.1387C4.41841 17.779 4.40245 18.8332 5.04282 19.4932C5.6832 20.1532 6.73733 20.1692 7.39738 19.5288C7.41112 19.5155 7.42462 19.5019 7.43789 19.4881L10.0022 16.8339L12.5714 19.4931C13.2113 20.1536 14.2654 20.1704 14.926 19.5306C15.5865 18.8907 15.6033 17.8366 14.9634 17.1761C14.95 17.1622 14.9364 17.1487 14.9226 17.1353L12.4107 14.6776C12.5667 14.6376 12.7185 14.5952 12.8661 14.5502C13.7292 14.2796 14.5497 13.888 15.303 13.3872Z"
              fill="#0092A1"
            ></path>
          </svg>
        </a>
      </div>
      <div className="social__item">
        <a
          href="https://vk.com/public212755320"
          target="_blank"
          rel="noreferrer"
          className="social__link"
        >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.80805 14.5451H10.8703C10.8703 14.5451 11.1915 14.5096 11.3548 14.3329C11.5059 14.1706 11.5004 13.8662 11.5004 13.8662C11.5004 13.8662 11.4793 12.4405 12.1416 12.2305C12.7938 12.0238 13.6317 13.6084 14.5195 14.2184C15.1907 14.6796 15.7007 14.5785 15.7007 14.5785L18.0753 14.5451C18.0753 14.5451 19.3176 14.4684 18.7287 13.4917C18.6809 13.4117 18.3853 12.7694 16.963 11.4493C15.474 10.067 15.674 10.2915 17.4675 7.90129C18.5598 6.44563 18.9965 5.55667 18.8598 5.17665C18.7298 4.8144 17.9264 4.90996 17.9264 4.90996L15.2551 4.92552C15.2551 4.92552 15.0573 4.89885 14.9095 4.98663C14.7662 5.0733 14.674 5.27332 14.674 5.27332C14.674 5.27332 14.2506 6.40007 13.6861 7.35792C12.496 9.37918 12.0193 9.48585 11.8249 9.36029C11.3726 9.06805 11.4859 8.18465 11.4859 7.55793C11.4859 5.5989 11.7826 4.78217 10.907 4.57105C10.6159 4.50104 10.4025 4.45437 9.65914 4.44659C8.70574 4.43659 7.8979 4.44993 7.4412 4.67327C7.13674 4.82217 6.90227 5.15442 7.04562 5.17331C7.2223 5.19665 7.62233 5.2811 7.83457 5.57001C8.10903 5.94226 8.09903 6.7801 8.09903 6.7801C8.09903 6.7801 8.25682 9.08582 7.73122 9.37251C7.37009 9.56919 6.87561 9.16805 5.81442 7.33347C5.27104 6.39451 4.8599 5.35555 4.8599 5.35555C4.8599 5.35555 4.78101 5.1622 4.63988 5.05886C4.46876 4.93329 4.22874 4.89329 4.22874 4.89329L1.68855 4.90885C1.68855 4.90885 1.30742 4.91996 1.16741 5.08553C1.04295 5.23332 1.1574 5.53778 1.1574 5.53778C1.1574 5.53778 3.14644 10.1904 5.39772 12.5361C7.46343 14.6862 9.80805 14.5451 9.80805 14.5451Z"
            fill="#0092A1"
          ></path>
        </svg>
        </a>
      </div>
    </div>
  );
};
export default Social;
