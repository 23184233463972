// import logo from "./IMAGE1.svg";
// import { IoIosArrowDown } from "react-icons/io";
// import "./Header.css";

// const Header = ({ isOpenMenu, setIsOpenMenu }) => {
//   const openMenu = () => {
//     setIsOpenMenu(!isOpenMenu);
//   };
//   return (
//     <header className="header">
//       <div className="container container--header ">
//         <div className="header__wrapper">
//           <div className="header__left">
//             <div className="header__left-logo-wrapper">

//               <a href="https://mbank.kg" className="header__left-logo">
//                 <img src={logo} alt="logo" />
//               </a>
//             </div>

//             <p className="header__left-text">
//               ОАО «Коммерческий банк КЫРГЫЗСТАН»
//             </p>

//             <ul className="header__left-list">
//               <li className="header__left-item header__left-item--first">
//                 <a
//                   href="https://mbank.kg"
//                   className="header__left-link header__left-link--first"
//                 >
//                   ФИЗ. <br /> ЛИЦАМ
//                 </a>
//               </li>

//               <li className="header__left-item header__left-item--middle">
//                 <a
//                   href="https://mbank.kg/yuridichecskiye-litsa"
//                   className="header__left-link header__left-link--middle"
//                 >
//                   ЮР. <br /> ЛИЦАМ
//                 </a>
//               </li>

//               <li className="header__left-item header__left-item--last">
//                 <a
//                   href="https://mbank.kg/islam_finance"
//                   className="header__left-link header__left-link--last"
//                 >
//                   ИСЛАМСКИЕ <br /> ФИНАНСЫ
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="header__right">
//             <div className="header__right-row">
//               <div className="header__right-item header__right-item--location">
//                 <a
//                   href="https://mbank.kg/maps"
//                   className="header__right-link header__right-link--location"
//                 >
//                   <p>
//                     <svg
//                       width="26"
//                       height="26"
//                       viewBox="0 0 26 26"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M13 13.8125C14.7949 13.8125 16.25 12.3574 16.25 10.5625C16.25 8.76757 14.7949 7.3125 13 7.3125C11.2051 7.3125 9.75 8.76757 9.75 10.5625C9.75 12.3574 11.2051 13.8125 13 13.8125Z"
//                         stroke="#7893B0"
//                         strokeWidth="1.6"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                       ></path>
//                       <path
//                         d="M21.125 10.5625C21.125 17.875 13 23.5625 13 23.5625C13 23.5625 4.875 17.875 4.875 10.5625C4.875 8.40762 5.73102 6.34099 7.25476 4.81726C8.77849 3.29352 10.8451 2.4375 13 2.4375C15.1549 2.4375 17.2215 3.29352 18.7452 4.81726C20.269 6.34099 21.125 8.40762 21.125 10.5625V10.5625Z"
//                         stroke="#7893B0"
//                         strokeWidth="1.6"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                       ></path>
//                     </svg>
//                   </p>
//                   офисы и <br /> банкоматы
//                 </a>
//               </div>
//               <div
//                 onClick={openMenu}
//                 className={
//                   isOpenMenu
//                     ? "header__right-item header__right-item--language active"
//                     : "header__right-item header__right-item--language"
//                 }
//               >
//                 <div className="header__right-link header__right-link--language">
//                   <p>
//                     <svg
//                       width="26"
//                       height="26"
//                       viewBox="0 0 26 26"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M13 22.75C18.3848 22.75 22.75 18.3848 22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75Z"
//                         stroke="#7893B0"
//                         strokeWidth="1.6"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                       ></path>
//                       <path
//                         d="M3.80859 9.75H22.1914"
//                         stroke="#7893B0"
//                         strokeWidth="1.6"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                       ></path>
//                       <path
//                         d="M3.80859 16.25H22.1914"
//                         stroke="#7893B0"
//                         strokeWidth="1.6"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                       ></path>
//                       <path
//                         d="M13 22.4865C15.2437 22.4865 17.0625 18.2395 17.0625 13.0006C17.0625 7.76165 15.2437 3.51465 13 3.51465C10.7563 3.51465 8.9375 7.76165 8.9375 13.0006C8.9375 18.2395 10.7563 22.4865 13 22.4865Z"
//                         stroke="#7893B0"
//                         strokeWidth="1.6"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                       ></path>
//                     </svg>
//                   </p>
//                   русский
//                   <span
//                     className={
//                       isOpenMenu
//                         ? "header__right-arrow header__right-arrow--open"
//                         : "header__right-arrow"
//                     }
//                   >
//                     <IoIosArrowDown className="header__right-arrow-icon" />
//                   </span>
//                 </div>
//                 <div className="header__right-submenu">
//                   <div className="header__right-submenu-wrapper">
//                     <div className="header__right-submenu-item">
//                       <a
//                         className="header__right-submenu-link"
//                         href="/ru"
//                       >
//                         Русский
//                       </a>
//                     </div>
//                     <div className="header__right-submenu-item">
//                       <a
//                         className="header__right-submenu-link"
//                         href="/en"
//                       >
//                         English
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };
// export default Header;


import { Link } from "react-router-dom";
import logo from "./IMAGE1.svg";
import { IoIosArrowDown } from "react-icons/io";
import "./Header.css";

const Header = ({ isOpenMenu, setIsOpenMenu }) => {
  const openMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <header className="header">
      <div className="container container--header ">
        <div className="header__wrapper">
          <div className="header__left">
            <div className="header__left-logo-wrapper">
              <Link to="https://mbank.kg" className="header__left-logo">
                <img src={logo} alt="logo" />
              </Link>
            </div>

            <p className="header__left-text">
              ОАО «Коммерческий банк КЫРГЫЗСТАН»
            </p>

            <ul className="header__left-list">
              <li className="header__left-item header__left-item--first">
                <Link to="https://mbank.kg" className="header__left-link header__left-link--first">
                  ФИЗ. <br /> ЛИЦАМ
                </Link>
              </li>
              <li className="header__left-item header__left-item--middle">
                <Link to="https://mbank.kg/yuridichecskiye-litsa" className="header__left-link header__left-link--middle">
                  ЮР. <br /> ЛИЦАМ
                </Link>
              </li>
              <li className="header__left-item header__left-item--last">
                <Link to="https://mbank.kg/islam_finance" className="header__left-link header__left-link--last">
                  ИСЛАМСКИЕ <br /> ФИНАНСЫ
                </Link>
              </li>
            </ul>
          </div>

          <div className="header__right">
            <div className="header__right-row">
              <div className="header__right-item header__right-item--location">
                <Link to="https://mbank.kg/maps" className="header__right-link header__right-link--location">
                  <p>
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 13.8125C14.7949 13.8125 16.25 12.3574 16.25 10.5625C16.25 8.76757 14.7949 7.3125 13 7.3125C11.2051 7.3125 9.75 8.76757 9.75 10.5625C9.75 12.3574 11.2051 13.8125 13 13.8125Z"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M21.125 10.5625C21.125 17.875 13 23.5625 13 23.5625C13 23.5625 4.875 17.875 4.875 10.5625C4.875 8.40762 5.73102 6.34099 7.25476 4.81726C8.77849 3.29352 10.8451 2.4375 13 2.4375C15.1549 2.4375 17.2215 3.29352 18.7452 4.81726C20.269 6.34099 21.125 8.40762 21.125 10.5625V10.5625Z"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </p>
                  офисы и <br /> банкоматы
                </Link>
              </div>

              <div
                onClick={openMenu}
                className={
                  isOpenMenu
                    ? "header__right-item header__right-item--language active"
                    : "header__right-item header__right-item--language"
                }
              >
                <div className="header__right-link header__right-link--language">
                  <p>
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 22.75C18.3848 22.75 22.75 18.3848 22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75Z"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M3.80859 9.75H22.1914"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M3.80859 16.25H22.1914"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M13 22.4865C15.2437 22.4865 17.0625 18.2395 17.0625 13.0006C17.0625 7.76165 15.2437 3.51465 13 3.51465C10.7563 3.51465 8.9375 7.76165 8.9375 13.0006C8.9375 18.2395 10.7563 22.4865 13 22.4865Z"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </p>
                  русский
                  <span
                    className={
                      isOpenMenu
                        ? "header__right-arrow header__right-arrow--open"
                        : "header__right-arrow"
                    }
                  >
                    <IoIosArrowDown className="header__right-arrow-icon" />
                  </span>
                </div>

                <div className="header__right-submenu">
                  <div className="header__right-submenu-wrapper">
                    <div className="header__right-submenu-item">
                      <Link to="/ru" className="header__right-submenu-link">Русский</Link>
                    </div>
                    <div className="header__right-submenu-item">
                      <Link to="/en" className="header__right-submenu-link">English</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
