import './Cards.css'
const Cards = () => {
  return (
    <div className="cards">
        <div className="cards__wrapper">
          <h2 className="cards__heading">Преимущества инвестирования в Financial Horizon:</h2>
          <div className="cards__inner">
          <div className="cards__item">
            <span className='number'>1</span>
            <h3 className="cards__title">Надежность и стабильность</h3>
            <p className="cards__text"><span>Financial Horizon</span>, при поддержке Mbank, основан на принципах надежности и стабильности. Команда профессионалов тщательно анализирует рыночные тенденции и разрабатывает стратегии, направленные на минимизацию рисков и максимизацию доходности для клиентов.</p>
          </div>
          <div className="cards__item">
            <span className='number'>2</span>
            <h3 className="cards__title">Диверсификация портфеля</h3>
            <p className="cards__text"><span>Financial Horizon</span> предлагает широкий выбор инвестиционных продуктов, что позволяет клиентам диверсифицировать свои портфели и снизить риск потерь.</p>
          </div>
          <div className="cards__item">
            <span className='number'>3</span>
            <h3 className="cards__title">Профессиональное управление активами</h3>
            <p className="cards__text">Команда экспертов обладает высоким уровнем компетенции и опыта в управлении активами. Они постоянно отслеживают изменения на финансовых рынках и своевременно реагируют на новые возможности, чтобы обеспечить максимальную эффективность инвестиций.</p>
          </div>
          <div className="cards__item">
            <span className='number'>4</span>
            <h3 className="cards__title">Индивидуальный подход к каждому клиенту</h3>
            <p className="cards__text">Каждый клиент <span>Financial Horizon</span> получает персонализированное инвестиционное решение, разработанное с учетом его финансовых целей, инвестиционного опыта и уровня риска.</p>
          </div>

        </div>
      </div>
    </div>
  )
}
export default Cards