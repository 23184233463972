  
const CardsEng = () => {
  return (
    <div className="cards">
      <div className="cards__wrapper">
        <h2 className="cards__heading">Advantages of Investing in Financial Horizon:</h2>
        <div className="cards__inner">
          <div className="cards__item">
            <span className='number'>1</span>
            <h3 className="cards__title">Reliability and Stability</h3>
            <p className="cards__text"><span>Financial Horizon</span>, supported by Mbank, is based on principles of reliability and stability. A team of professionals carefully analyzes market trends and develops strategies aimed at minimizing risks and maximizing returns for clients.</p>
          </div>
          <div className="cards__item">
            <span className='number'>2</span>
            <h3 className="cards__title">Portfolio Diversification</h3>
            <p className="cards__text"><span>Financial Horizon</span> offers a wide range of investment products, allowing clients to diversify their portfolios and reduce the risk of losses.</p>
          </div>
          <div className="cards__item">
            <span className='number'>3</span>
            <h3 className="cards__title">Professional Asset Management</h3>
            <p className="cards__text">The team of experts possesses a high level of competence and experience in asset management. They constantly monitor changes in financial markets and promptly react to new opportunities to ensure maximum investment efficiency.</p>
          </div>
          <div className="cards__item">
            <span className='number'>4</span>
            <h3 className="cards__title">Individual Approach to Each Client</h3>
            <p className="cards__text">Each client of <span>Financial Horizon</span> receives a personalized investment solution tailored to their financial goals, investment experience, and risk level.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsEng;
