
const EndEng = () => {
  return (
    <section className="End">
      <div className="End__wrapper">
        <h2 className='End__heading'>Conclusion: </h2>
        <div className="End__content">
          <div className="End__text">The investment project <span>Financial Horizon</span>, supported by Mbank, represents a reliable path to financial success. The combination of the team's high professionalism, a wide range of investment products, and an individual approach to each client makes this project an attractive choice for anyone seeking capital growth and financial stability in the future.
            <p className='End__link-p'>Link to the platform:</p>
            <a className='End__link' href="https://financial-horizon.co">https://financial-horizon.co</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EndEng;
