import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FooterEng from "../FooterEng/FooterEng";
import HeaderBottom from "../Header/HeaderBottom";

import HeaderEng from "../HeaderEng/HeaderEng";
import HeaderBottomEng from "../HeaderEng/HeaderBottomEng";
import "./App.css";
import Promo from "../Promo/Promo";
import PromoEng from "../PromoEng/PromoEng";
// import Cards from "../Cards/Cards";

const RussianPage = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <div className="App">
      <Header isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
      <HeaderBottom />
      <main className="home">
        <Promo />
      </main>
      <Footer />
    </div>
  );
};

const EnglishPage = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  return (
    <div className="App">
      <HeaderEng isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
      <HeaderBottomEng />
      <main className="home">
        <PromoEng />
      </main>
      <FooterEng />
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/ru" element={<RussianPage />} />
        <Route path="/en" element={<EnglishPage />} />
        <Route path="*" element={<RussianPage />} />
      </Routes>
    </Router>
  );
}

export default App;
