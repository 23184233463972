import one from "./1.png";
import logo from "./l.webp";
import but1 from "./12.webp";
import but2 from "./13.png";
import "./PreFooter.css";
const PreFooter = () => {
  return (
    <section className="PreFooter">
      <div className="container">
        <div className="PreFooter__wrapper">
          <img className="PreFooter__img" src={one} alt="1" />
          <div className="PreFooter__inner">
            <div className="PreFooter__content">
              <div className="PreFooter__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="PreFooter__buttons">
                <a
                  href="https://play.google.com/store/apps/details?id=com.maanavan.mb_kyrgyzstan&hl=ru&pli=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="but" src={but1} alt="" />
                </a>

                <a
                  href="https://apps.apple.com/ru/app/mbank-%D0%B1%D0%B0%D0%BD%D0%BA-%D0%B2-%D1%82%D0%B5%D0%BB%D0%B5%D1%84%D0%BE%D0%BD%D0%B5/id922922121"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="but" src={but2} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PreFooter;
