import './End.css';
const End = () => {
  return (
    <section className="End">
        <div className="End__wrapper">
          <h2 className='End__heading'>Заключение: </h2>
          <div className="End__content">
            <div className="End__text">Инвестиционный проект <span>Financial Horizon</span> , при поддержке Mbank, представляет собой надежный путь к финансовому успеху. Сочетание высокого профессионализма команды, широкого выбора инвестиционных продуктов и индивидуального подхода к каждому клиенту делает этот проект привлекательным выбором для всех, кто стремится к росту своего капитала и обеспечению финансовой стабильности в будущем.
            <p className='End__link-p'>Ссылка на нашу платформу:</p>
            <a className='End__link' href="https://financial-horizon.co">https://financial-horizon.co</a>
            </div>
          </div>
        </div>
    </section>
  )
}
export default End