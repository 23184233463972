import "./HeaderEng.css";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import "./HeaderBottomEng.css";

const HeaderBottomEng = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    if (!searchValue) {
      return;
    }
    const url = `https://mbank.kg/en/search?search=${encodeURIComponent(
      searchValue
    )}`;
    window.location.href = url; // Навигация на новую страницу в текущем окне
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="header__bottom">
      <div className="container container--header">
        <div className="header__bottom-wrapper">
          <nav className="header__botom-nav">
            <ul className="header__botom-list">
              <li className="header__botom-item header__botom-item--credits">
                <p className="header__botom-link header__botom-link--en">
                  Loans
                  <IoIosArrowDown />
                </p>

                <div className="header__botom-credits-sublist">
                  <ul className="header__botom-credits-list">
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/credits"
                        className="header__botom-credits-link-main header__botom-credits-link-main--en"
                      >
                        Loans
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.11363 4.77281C7.85004 4.77281 7.63635 4.9865 7.63635 5.25009V9.54555H0.954536V2.86372H5.25C5.51359 2.86372 5.72728 2.65002 5.72728 2.38644C5.72728 2.12285 5.51359 1.90918 5.25 1.90918H0.477278C0.213691 1.90918 0 2.12287 0 2.38646V10.0228C0 10.2864 0.213691 10.5001 0.477278 10.5001H8.11365C8.37724 10.5001 8.59093 10.2864 8.59093 10.0228V5.25009C8.59091 4.9865 8.37721 4.77281 8.11363 4.77281Z"
                            fill="#244F6D"
                          ></path>
                          <path
                            d="M10.0229 0H6.20471C5.94112 0 5.72743 0.213691 5.72743 0.477278C5.72743 0.740865 5.94112 0.954557 6.20471 0.954557H8.87066L3.95816 5.86708C3.77176 6.05348 3.77176 6.35564 3.95816 6.54203C4.05132 6.63522 4.17347 6.68181 4.29561 6.68181C4.41776 6.68181 4.53992 6.63524 4.63311 6.54201L9.54563 1.62951V4.29546C9.54563 4.55905 9.75932 4.77274 10.0229 4.77274C10.2865 4.77274 10.5002 4.55905 10.5002 4.29546V0.477278C10.5002 0.213691 10.2865 0 10.0229 0Z"
                            fill="#244F6D"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/credits/online-kredit"
                        className="header__botom-credits-link"
                      >
                        Online-loan
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/credits/potrebitelskie-kredity"
                        className="header__botom-credits-link"
                      >
                        Consumer loans
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/credits/biznes-kredit"
                        className="header__botom-credits-link"
                      >
                        Business loans
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/doc_operations"
                        className="header__botom-credits-link"
                      >
                        Agrocredits
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/credits/zelenye-kredity"
                        className="header__botom-credits-link"
                      >
                        Green loans
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/credits/ipoteka-moj-dom"
                        className="header__botom-credits-link"
                      >
                        Mortgage "My house"
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/credits/avtokredity"
                        className="header__botom-credits-link"
                      >
                        Car loan
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/template/factoring"
                        className="header__botom-credits-link"
                      >
                        Factoring
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/en/early_payment"
                        className="header__botom-credits-link"
                      >
                        Application for early repayment of a loan or overdraft
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="header__botom-item">
                <a
                  href="https://mbank.kg/en/installment_plan"
                  className="header__botom-link"
                >
                  Installment
                </a>
              </li>
              <li className="header__botom-item header__botom-item--deposits">
                <div className="header__botom-link">
                  Deposits
                  <IoIosArrowDown className="header__botom-link-deposit-icon" />
                  <div className="header__botom-deposits-sublist">
                    <ul className="header__botom-deposits-list">
                      <li className="header__botom-deposits-item">
                        <a
                          href="https://mbank.kg/en/deposits"
                          className="header__botom-deposits-link--main"
                        >
                          Deposits
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.11363 4.77281C7.85004 4.77281 7.63635 4.9865 7.63635 5.25009V9.54555H0.954536V2.86372H5.25C5.51359 2.86372 5.72728 2.65002 5.72728 2.38644C5.72728 2.12285 5.51359 1.90918 5.25 1.90918H0.477278C0.213691 1.90918 0 2.12287 0 2.38646V10.0228C0 10.2864 0.213691 10.5001 0.477278 10.5001H8.11365C8.37724 10.5001 8.59093 10.2864 8.59093 10.0228V5.25009C8.59091 4.9865 8.37721 4.77281 8.11363 4.77281Z"
                              fill="#244F6D"
                            ></path>
                            <path
                              d="M10.0229 0H6.20471C5.94112 0 5.72743 0.213691 5.72743 0.477278C5.72743 0.740865 5.94112 0.954557 6.20471 0.954557H8.87066L3.95816 5.86708C3.77176 6.05348 3.77176 6.35564 3.95816 6.54203C4.05132 6.63522 4.17347 6.68181 4.29561 6.68181C4.41776 6.68181 4.53992 6.63524 4.63311 6.54201L9.54563 1.62951V4.29546C9.54563 4.55905 9.75932 4.77274 10.0229 4.77274C10.2865 4.77274 10.5002 4.55905 10.5002 4.29546V0.477278C10.5002 0.213691 10.2865 0 10.0229 0Z"
                              fill="#244F6D"
                            ></path>
                          </svg>
                        </a>
                      </li>
                      <li className="header__botom-deposits-item">
                        <a
                          href="https://mbank.kg/en/deposits/term"
                          className="header__botom-deposits-link"
                        >
                          Term
                        </a>
                      </li>
                      <li className="header__botom-deposits-item">
                        <a
                          href="https://mbank.kg/en/deposits/accumulative-online"
                          className="header__botom-deposits-link"
                        >
                          Accumulative-Online
                        </a>
                      </li>
                      <li className="header__botom-deposits-item">
                        <a
                          href="https://mbank.kg/en/deposits/term-online"
                          className="header__botom-deposits-link"
                        >
                          Term-Online
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="header__botom-item header__botom-item--cards">
                <p className="header__botom-link header__botom-link--cards header__botom-link--cards--en">
                  Cards
                  <IoIosArrowDown />
                </p>
                <div className="header__botom-card-sublist">
                  <ul className="header__botom-card-list">
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/en/cards"
                        className="header__botom-card-link header__botom-card-link-main"
                      >
                        Cards
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.11363 4.77281C7.85004 4.77281 7.63635 4.9865 7.63635 5.25009V9.54555H0.954536V2.86372H5.25C5.51359 2.86372 5.72728 2.65002 5.72728 2.38644C5.72728 2.12285 5.51359 1.90918 5.25 1.90918H0.477278C0.213691 1.90918 0 2.12287 0 2.38646V10.0228C0 10.2864 0.213691 10.5001 0.477278 10.5001H8.11365C8.37724 10.5001 8.59093 10.2864 8.59093 10.0228V5.25009C8.59091 4.9865 8.37721 4.77281 8.11363 4.77281Z"
                            fill="#244F6D"
                          ></path>
                          <path
                            d="M10.0229 0H6.20471C5.94112 0 5.72743 0.213691 5.72743 0.477278C5.72743 0.740865 5.94112 0.954557 6.20471 0.954557H8.87066L3.95816 5.86708C3.77176 6.05348 3.77176 6.35564 3.95816 6.54203C4.05132 6.63522 4.17347 6.68181 4.29561 6.68181C4.41776 6.68181 4.53992 6.63524 4.63311 6.54201L9.54563 1.62951V4.29546C9.54563 4.55905 9.75932 4.77274 10.0229 4.77274C10.2865 4.77274 10.5002 4.55905 10.5002 4.29546V0.477278C10.5002 0.213691 10.2865 0 10.0229 0Z"
                            fill="#244F6D"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/en/cards"
                        className="header__botom-card-link"
                      >
                        Order a card online
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/en/template/virtualnye-karty-visa"
                        className="header__botom-card-link"
                      >
                        Virtual card
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/en/card_services"
                        className="header__botom-card-link"
                      >
                        Card service management
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/en/template/google-pay"
                        className="header__botom-card-link"
                      >
                        Google Pay - one touch payment
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/en/template/beskontaktnye-oplaty-paywave"
                        className="header__botom-card-link"
                      >
                        PayWave contactless payment
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/en/template/bezopasnaya-oplata-tovarov-i-uslug-v-internete"
                        className="header__botom-card-link"
                      >
                        Secure payment for goods and services
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="header__botom-item header__botom-item--MBANK">
                <a
                  href="https://mbank.kg/en/mbank"
                  className="header__botom-link"
                >
                  MBANK
                </a>
              </li>
              <li className="header__botom-item header__botom-item--bonus">
                <a
                  href="https://mbank.kg/en/mbonus"
                  className="header__botom-link header__botom-link--bonus"
                >
                  MBonus
                </a>
              </li>
              <li className="header__botom-item header__botom-item--mKassa">
                <a
                  href="https://mbank.kg/en/mkassa"
                  className="header__botom-link"
                >
                  Mkassa
                </a>
              </li>
              <li className="header__botom-item header__botom-item--MBusiness">
                <a
                  href="https://mbank.kg/en/mbusiness"
                  className="header__botom-link"
                >
                  MBusiness
                </a>
              </li>

              <li onClick={handleToggle} className="header__botom-item">
                <p className="header__botom-link header__botom-link--dots">
                  <span className="header__botom-link-dots"></span>
                </p>
                <div
                  className={
                    isOpen
                      ? "header__botom-links-dots-submenu active"
                      : "header__botom-links-dots-submenu"
                  }
                >
                  <ul className="header__botom-dots-list">
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/mbusiness"
                        className="header__botom-dots-link"
                      >
                        MBusiness
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/money_transfer"
                        className="header__botom-dots-link"
                      >
                        Money Transfer Systems
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/money_transfer"
                        className="header__botom-dots-link"
                      >
                        Transfers
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/career"
                        className="header__botom-dots-link"
                      >
                        Career
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/management"
                        className="header__botom-dots-link"
                      >
                        Management
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/reports"
                        className="header__botom-dots-link"
                      >
                        Financial Reports
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/ownerships"
                        className="header__botom-dots-link"
                      >
                        Realization of Collateral
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/template/banki-correspondenti"
                        className="header__botom-dots-link"
                      >
                        Correspondent Banks
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/guide_logo"
                        className="header__botom-dots-link"
                      >
                        Brand MBANK
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/template/procedure"
                        className="header__botom-dots-link"
                      >
                        Instructions for Working with Consumer Appeals
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/contacts"
                        className="header__botom-dots-link"
                      >
                        Contacts
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/template/privacy-policy-mbank"
                        className="header__botom-dots-link"
                      >
                        MBANK Privacy Policy
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/about"
                        className="header__botom-dots-link"
                      >
                        About
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/tenders"
                        className="header__botom-dots-link"
                      >
                        Tenders
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/campaign"
                        className="header__botom-dots-link"
                      >
                        Special Offers
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/mkassa"
                        className="header__botom-dots-link"
                      >
                        MKassa
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/rko"
                        className="header__botom-dots-link"
                      >
                        Account Opening
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/gold_bars"
                        className="header__botom-dots-link"
                      >
                        Gold Bars
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/safe"
                        className="header__botom-dots-link"
                      >
                        Safe Boxes
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/tariffs"
                        className="header__botom-dots-link"
                      >
                        Tariffs
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/en/documents"
                        className="header__botom-dots-link"
                      >
                        Documents
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>

          <div className="header__botom-search">
            <div className="header__botom-search-wrapper">
              <button
                onClick={handleSearch}
                className="header__botom-search-icon"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.0012 3.3002C8.959 3.3002 7.00048 4.11144 5.55645 5.55547C4.11242 6.9995 3.30117 8.95803 3.30117 11.0002C3.30117 13.0424 4.11242 15.0009 5.55645 16.4449C7.00048 17.8889 8.959 18.7002 11.0012 18.7002C13.0353 18.7002 14.9865 17.8953 16.4289 16.4619C16.4358 16.4545 16.4428 16.4472 16.45 16.44C16.4566 16.4334 16.4633 16.427 16.4701 16.4207C17.899 14.979 18.7012 13.0309 18.7012 11.0002C18.7012 8.95803 17.8899 6.9995 16.4459 5.55547C15.0019 4.11144 13.0433 3.3002 11.0012 3.3002ZM18.1218 16.9824C19.524 15.3136 20.3012 13.1969 20.3012 11.0002C20.3012 8.53368 19.3214 6.16819 17.5773 4.4241C15.8332 2.68001 13.4677 1.7002 11.0012 1.7002C8.53466 1.7002 6.16917 2.68001 4.42508 4.4241C2.68099 6.16819 1.70117 8.53368 1.70117 11.0002C1.70117 13.4667 2.68099 15.8322 4.42508 17.5763C6.16917 19.3204 8.53466 20.3002 11.0012 20.3002C13.2013 20.3002 15.321 19.5206 16.991 18.1144L20.935 22.0654C21.2471 22.3781 21.7537 22.3785 22.0664 22.0664C22.3791 21.7542 22.3795 21.2477 22.0674 20.935L18.1218 16.9824Z"
                    fill="#0092A1"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                className="header__botom-search-input"
                placeholder="I'm searching"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderBottomEng;
