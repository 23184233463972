import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import "./HeaderBottom.css";

const HeaderBottom = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    if (!searchValue) {
      return;
    }
    const url = `https://mbank.kg/search?search=${encodeURIComponent(
      searchValue
    )}`;
    window.location.href = url; // Навигация на новую страницу в текущем окне
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="header__bottom">
      <div className="container container--header">
        <div className="header__bottom-wrapper">
          <nav className="header__botom-nav">
            <ul className="header__botom-list">
              <li className="header__botom-item header__botom-item--credits">
                <p className="header__botom-link">
                  Кредиты
                  <IoIosArrowDown />
                </p>

                <div className="header__botom-credits-sublist">
                  <ul className="header__botom-credits-list">
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/credits"
                        className="header__botom-credits-link-main"
                      >
                        Кредиты
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.11363 4.77281C7.85004 4.77281 7.63635 4.9865 7.63635 5.25009V9.54555H0.954536V2.86372H5.25C5.51359 2.86372 5.72728 2.65002 5.72728 2.38644C5.72728 2.12285 5.51359 1.90918 5.25 1.90918H0.477278C0.213691 1.90918 0 2.12287 0 2.38646V10.0228C0 10.2864 0.213691 10.5001 0.477278 10.5001H8.11365C8.37724 10.5001 8.59093 10.2864 8.59093 10.0228V5.25009C8.59091 4.9865 8.37721 4.77281 8.11363 4.77281Z"
                            fill="#244F6D"
                          ></path>
                          <path
                            d="M10.0229 0H6.20471C5.94112 0 5.72743 0.213691 5.72743 0.477278C5.72743 0.740865 5.94112 0.954557 6.20471 0.954557H8.87066L3.95816 5.86708C3.77176 6.05348 3.77176 6.35564 3.95816 6.54203C4.05132 6.63522 4.17347 6.68181 4.29561 6.68181C4.41776 6.68181 4.53992 6.63524 4.63311 6.54201L9.54563 1.62951V4.29546C9.54563 4.55905 9.75932 4.77274 10.0229 4.77274C10.2865 4.77274 10.5002 4.55905 10.5002 4.29546V0.477278C10.5002 0.213691 10.2865 0 10.0229 0Z"
                            fill="#244F6D"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/credits/online-kredit"
                        className="header__botom-credits-link"
                      >
                        Онлайн-кредит
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/credits/potrebitelskie-kredity"
                        className="header__botom-credits-link"
                      >
                        Потребительские кредиты
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/credits/biznes-kredit"
                        className="header__botom-credits-link"
                      >
                        Бизнес-кредиты
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/doc_operations"
                        className="header__botom-credits-link"
                      >
                        Документарные операции
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/credits/agrokredit"
                        className="header__botom-credits-link"
                      >
                        Агрокредиты
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/credits/zelenye-kredity"
                        className="header__botom-credits-link"
                      >
                        Зеленые кредиты
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/credits/ipoteka-moj-dom"
                        className="header__botom-credits-link"
                      >
                        Ипотека "Мой дом"
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/credits/avtokredity"
                        className="header__botom-credits-link"
                      >
                        Автокредит
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/template/factoring"
                        className="header__botom-credits-link"
                      >
                        Факторинг
                      </a>
                    </li>
                    <li className="header__botom-credits-item">
                      <a
                        href="https://mbank.kg/early_payment"
                        className="header__botom-credits-link"
                      >
                        Заявление на досрочное погашение кредита или овердрафта
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="header__botom-item">
                <a
                  href="https://mbank.kg/installment_plan"
                  className="header__botom-link"
                >
                  Рассрочка
                </a>
              </li>
              <li className="header__botom-item header__botom-item--deposits">
                <div className="header__botom-link">
                  Депозиты
                  <IoIosArrowDown />
                  <div className="header__botom-deposits-sublist">
                    <ul className="header__botom-deposits-list">
                      <li className="header__botom-deposits-item">
                        <a
                          href="https://mbank.kg/deposits"
                          className="header__botom-deposits-link--main"
                        >
                          Депозиты
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.11363 4.77281C7.85004 4.77281 7.63635 4.9865 7.63635 5.25009V9.54555H0.954536V2.86372H5.25C5.51359 2.86372 5.72728 2.65002 5.72728 2.38644C5.72728 2.12285 5.51359 1.90918 5.25 1.90918H0.477278C0.213691 1.90918 0 2.12287 0 2.38646V10.0228C0 10.2864 0.213691 10.5001 0.477278 10.5001H8.11365C8.37724 10.5001 8.59093 10.2864 8.59093 10.0228V5.25009C8.59091 4.9865 8.37721 4.77281 8.11363 4.77281Z"
                              fill="#244F6D"
                            ></path>
                            <path
                              d="M10.0229 0H6.20471C5.94112 0 5.72743 0.213691 5.72743 0.477278C5.72743 0.740865 5.94112 0.954557 6.20471 0.954557H8.87066L3.95816 5.86708C3.77176 6.05348 3.77176 6.35564 3.95816 6.54203C4.05132 6.63522 4.17347 6.68181 4.29561 6.68181C4.41776 6.68181 4.53992 6.63524 4.63311 6.54201L9.54563 1.62951V4.29546C9.54563 4.55905 9.75932 4.77274 10.0229 4.77274C10.2865 4.77274 10.5002 4.55905 10.5002 4.29546V0.477278C10.5002 0.213691 10.2865 0 10.0229 0Z"
                              fill="#244F6D"
                            ></path>
                          </svg>
                        </a>
                      </li>
                      <li className="header__botom-deposits-item">
                        <a
                          href="https://mbank.kg/deposits/srochnyj-fiz"
                          className="header__botom-deposits-link"
                        >
                          Срочный
                        </a>
                      </li>
                      <li className="header__botom-deposits-item">
                        <a
                          href="https://mbank.kg/deposits/nakopitelnyj-onlajn"
                          className="header__botom-deposits-link"
                        >
                          Накопительный-онлайн
                        </a>
                      </li>
                      <li className="header__botom-deposits-item">
                        <a
                          href="https://mbank.kg/deposits/srochnyi-onlajn"
                          className="header__botom-deposits-link"
                        >
                          Срочный-онлайн
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="header__botom-item header__botom-item--cards">
                <p className="header__botom-link header__botom-link--cards">
                  Карты
                  <IoIosArrowDown />
                </p>
                <div className="header__botom-card-sublist">
                  <ul className="header__botom-card-list">
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/cards"
                        className="header__botom-card-link header__botom-card-link-main"
                      >
                        Карты
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.11363 4.77281C7.85004 4.77281 7.63635 4.9865 7.63635 5.25009V9.54555H0.954536V2.86372H5.25C5.51359 2.86372 5.72728 2.65002 5.72728 2.38644C5.72728 2.12285 5.51359 1.90918 5.25 1.90918H0.477278C0.213691 1.90918 0 2.12287 0 2.38646V10.0228C0 10.2864 0.213691 10.5001 0.477278 10.5001H8.11365C8.37724 10.5001 8.59093 10.2864 8.59093 10.0228V5.25009C8.59091 4.9865 8.37721 4.77281 8.11363 4.77281Z"
                            fill="#244F6D"
                          ></path>
                          <path
                            d="M10.0229 0H6.20471C5.94112 0 5.72743 0.213691 5.72743 0.477278C5.72743 0.740865 5.94112 0.954557 6.20471 0.954557H8.87066L3.95816 5.86708C3.77176 6.05348 3.77176 6.35564 3.95816 6.54203C4.05132 6.63522 4.17347 6.68181 4.29561 6.68181C4.41776 6.68181 4.53992 6.63524 4.63311 6.54201L9.54563 1.62951V4.29546C9.54563 4.55905 9.75932 4.77274 10.0229 4.77274C10.2865 4.77274 10.5002 4.55905 10.5002 4.29546V0.477278C10.5002 0.213691 10.2865 0 10.0229 0Z"
                            fill="#244F6D"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/cards"
                        className="header__botom-card-link"
                      >
                        Закажи карту онлайн
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/template/virtualnye-karty-visa"
                        className="header__botom-card-link"
                      >
                        Виртуальная карта
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/card_services"
                        className="header__botom-card-link"
                      >
                        Управление услугами карты
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/template/google-pay"
                        className="header__botom-card-link"
                      >
                        Google Pay - оплата в одно касание
                      </a>
                    </li>

                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/template/beskontaktnye-oplaty-paywave"
                        className="header__botom-card-link"
                      >
                        Бесконтактная оплата PayWave
                      </a>
                    </li>
                    <li className="header__botom-card-item">
                      <a
                        href="https://mbank.kg/template/bezopasnaya-oplata-tovarov-i-uslug-v-internete"
                        className="header__botom-card-link"
                      >
                        Безопасная оплата товаров и услуг
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="header__botom-item header__botom-item--MBANK">
                <a href="https://mbank.kg/mbank" className="header__botom-link">
                  MBANK
                </a>
              </li>
              <li className="header__botom-item header__botom-item--bonus">
                <a
                  href="https://mbank.kg/mbonus"
                  className="header__botom-link header__botom-link--bonus"
                >
                  MBonus
                </a>
              </li>
              <li className="header__botom-item header__botom-item--mKassa">
                <a
                  href="https://mbank.kg/mkassa"
                  className="header__botom-link"
                >
                  MKassa
                </a>
              </li>
              <li className="header__botom-item header__botom-item--MBusiness">
                <a
                  href="https://mbank.kg/mbusiness"
                  className="header__botom-link"
                >
                  MBusiness
                </a>
              </li>

              <li onClick={handleToggle} className="header__botom-item">
                <p className="header__botom-link header__botom-link--dots">
                  <span className="header__botom-link-dots"></span>
                </p>
                <div
                  className={
                    isOpen
                      ? "header__botom-links-dots-submenu active"
                      : "header__botom-links-dots-submenu"
                  }
                >
                  <ul className="header__botom-dots-list">
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/mbusiness"
                        className="header__botom-dots-link"
                      >
                        MBusiness
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/money_transfer"
                        className="header__botom-dots-link"
                      >
                        Системы денежных переводов
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/money_transfer"
                        className="header__botom-dots-link"
                      >
                        Переводы
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/career"
                        className="header__botom-dots-link"
                      >
                        Карьера
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/management"
                        className="header__botom-dots-link"
                      >
                        Руководство
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/reports"
                        className="header__botom-dots-link"
                      >
                        Финансовая отчетность
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/ownerships"
                        className="header__botom-dots-link"
                      >
                        Реализация имущества
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/template/banki-correspondenti"
                        className="header__botom-dots-link"
                      >
                        Банки-корреспонденты
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/guide_logo"
                        className="header__botom-dots-link"
                      >
                        Бренд MBANK
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/template/procedure"
                        className="header__botom-dots-link"
                      >
                        Инструкция по работе с обращениями потребителей
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/contacts"
                        className="header__botom-dots-link"
                      >
                        Контакты
                      </a>
                    </li>

                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/template/privacy-policy-mbank"
                        className="header__botom-dots-link"
                      >
                        Политика конфиденциальности MBANK
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/about"
                        className="header__botom-dots-link"
                      >
                        О Банке
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/tenders"
                        className="header__botom-dots-link"
                      >
                        Тендеры
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/campaign"
                        className="header__botom-dots-link"
                      >
                        Акции
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/mkassa"
                        className="header__botom-dots-link"
                      >
                        MКасса
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/rko"
                        className="header__botom-dots-link"
                      >
                        Открытие счета
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/gold_bars"
                        className="header__botom-dots-link"
                      >
                        Золотые слитки
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/safe"
                        className="header__botom-dots-link"
                      >
                        Сейфовые ячейки
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/tariffs"
                        className="header__botom-dots-link"
                      >
                        Тарифы
                      </a>
                    </li>
                    <li className="header__botom-dots-item">
                      <a
                        href="https://mbank.kg/documents"
                        className="header__botom-dots-link"
                      >
                        Документы
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>

          <div className="header__botom-search">
            <div className="header__botom-search-wrapper">
              <button
                onClick={handleSearch}
                className="header__botom-search-icon"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.0012 3.3002C8.959 3.3002 7.00048 4.11144 5.55645 5.55547C4.11242 6.9995 3.30117 8.95803 3.30117 11.0002C3.30117 13.0424 4.11242 15.0009 5.55645 16.4449C7.00048 17.8889 8.959 18.7002 11.0012 18.7002C13.0353 18.7002 14.9865 17.8953 16.4289 16.4619C16.4358 16.4545 16.4428 16.4472 16.45 16.44C16.4566 16.4334 16.4633 16.427 16.4701 16.4207C17.899 14.979 18.7012 13.0309 18.7012 11.0002C18.7012 8.95803 17.8899 6.9995 16.4459 5.55547C15.0019 4.11144 13.0433 3.3002 11.0012 3.3002ZM18.1218 16.9824C19.524 15.3136 20.3012 13.1969 20.3012 11.0002C20.3012 8.53368 19.3214 6.16819 17.5773 4.4241C15.8332 2.68001 13.4677 1.7002 11.0012 1.7002C8.53466 1.7002 6.16917 2.68001 4.42508 4.4241C2.68099 6.16819 1.70117 8.53368 1.70117 11.0002C1.70117 13.4667 2.68099 15.8322 4.42508 17.5763C6.16917 19.3204 8.53466 20.3002 11.0012 20.3002C13.2013 20.3002 15.321 19.5206 16.991 18.1144L20.935 22.0654C21.2471 22.3781 21.7537 22.3785 22.0664 22.0664C22.3791 21.7542 22.3795 21.2477 22.0674 20.935L18.1218 16.9824Z"
                    fill="#0092A1"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                className="header__botom-search-input"
                placeholder="Я ищу"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderBottom;
