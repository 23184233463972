import { Link } from "react-router-dom";
import './HeaderEng.css';

import logo from "./IMAGE1.svg";
import { IoIosArrowDown } from "react-icons/io";

const HeaderEng = ({ isOpenMenu, setIsOpenMenu }) => {
  const openMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };
  return (
    <header className="header header--en">
      <div className="container container--header container--header--en">
        <div className="header__wrapper header__wrapper--en">
          <div className="header__left header__left--en">
            <div className="header__left-logo-wrapper header__left-logo-wrapper--en">
              <a href="https://mbank.kg" className="header__left-logo header__left-logo--en">
                <img src={logo} alt="logo" />
              </a>
            </div>

            <p className="header__left-text header__left-text--en">
            «Commercial bank KYRGYZSTAN» OJSC
            </p>

            <ul className="header__left-list header__left-list--en">
              <li className="header__left-item header__left-item--first header__left-item--first--en">
                <a
                  href="https://mbank.kg/en"
                  className="header__left-link header__left-link--first header__left-link--first--en"
                >
                  INDIVIDUAL
                </a>
              </li>

              <li className="header__left-item header__left-item--middle header__left-item--middle--en">
                <a
                  href="https://mbank.kg/en/yuridichecskiye-litsa"
                  className="header__left-link header__left-link--middle header__left-link--middle--en"
                >
                  LEGAL ENTITIES
                </a>
              </li>

              <li className="header__left-item header__left-item--last--en header__left-item--last--en">
                <a
                  href="https://mbank.kg/en/islam_finance"
                  className="header__left-link header__left-link--last header__left-link--last--en"
                >
                  ISLAMIC FINANCE
                </a>
              </li>
            </ul>
          </div>
          <div className="header__right header__right--en">
            <div className="header__right-row header__right-row--en">
              <div className="header__right-item header__right-item--location header__right-item--location--en">
                <a
                  href="https://mbank.kg/en/maps"
                  className="header__right-link header__right-link--location header__right-link--location--en"
                >
                  <p>
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 13.8125C14.7949 13.8125 16.25 12.3574 16.25 10.5625C16.25 8.76757 14.7949 7.3125 13 7.3125C11.2051 7.3125 9.75 8.76757 9.75 10.5625C9.75 12.3574 11.2051 13.8125 13 13.8125Z"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M21.125 10.5625C21.125 17.875 13 23.5625 13 23.5625C13 23.5625 4.875 17.875 4.875 10.5625C4.875 8.40762 5.73102 6.34099 7.25476 4.81726C8.77849 3.29352 10.8451 2.4375 13 2.4375C15.1549 2.4375 17.2215 3.29352 18.7452 4.81726C20.269 6.34099 21.125 8.40762 21.125 10.5625V10.5625Z"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </p>
                  OFFICES AND ATMS
                </a>
              </div>
              <div
                onClick={openMenu}
                className={
                  isOpenMenu
                    ? "header__right-item header__right-item--language active header__right-item--language--en"
                    : "header__right-item header__right-item--language header__right-item--language--en"
                }
              >
                <div className="header__right-link header__right-link--language header__right-link--language--en">
                  <p>
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 22.75C18.3848 22.75 22.75 18.3848 22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75Z"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M3.80859 9.75H22.1914"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M3.80859 16.25H22.1914"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M13 22.4865C15.2437 22.4865 17.0625 18.2395 17.0625 13.0006C17.0625 7.76165 15.2437 3.51465 13 3.51465C10.7563 3.51465 8.9375 7.76165 8.9375 13.0006C8.9375 18.2395 10.7563 22.4865 13 22.4865Z"
                        stroke="#7893B0"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </p>
                  ENGLISH
                  <span
                    className={
                      isOpenMenu
                        ? "header__right-arrow header__right-arrow--open header__right-arrow--open--en"
                        : "header__right-arrow header__right-arrow--en"
                    }
                  >
                    <IoIosArrowDown className="header__right-arrow-icon header__right-arrow-icon--en" />
                  </span>
                </div>
                <div className="header__right-submenu header__right-submenu--en">
                  <div className="header__right-submenu-wrapper header__right-submenu-wrapper--en">
                    <div className="header__right-submenu-item header__right-submenu-item--en">
                      <Link
                        className="header__right-submenu-link header__right-submenu-link--en"
                        to="/ru"
                      >
                        Русский
                      </Link>
                    </div>
                    <div className="header__right-submenu-item header__right-submenu-item--en">
                      <Link
                        className="header__right-submenu-link header__right-submenu-link--en"
                        to="/en"
                      >
                        English
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default HeaderEng;
