import "./FooterEng.css";

import Social from "../Social/Social";
import CardsEng from "../CardsEng/CardsEng";
import EndEng from "../EndEng/EndEng";
import PreFooter from "../PreFooter/PreFooter";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container container--footer">
        <div className="footer__wrapper ">
          <CardsEng/>
          <EndEng />
          <PreFooter />
          <div className="footer__row">
            <div className="footer__item footer__item--social">
              <div className="footer__social-row">
                <p className="footer__social-number">3333</p>
                <div className="footer__social-text-wrapper">
                  <p className=" footer__social-text-top">Free calls</p>
                  <p className=" footer__social-text-bottom">
                    Megacom, Beeline, O!
                  </p>
                </div>
              </div>
              <div className="footer__social-social-wrapper">
                <Social />
              </div>
            </div>
            <div className="footer__item footer__item--call">
              <div className="footer__call-column">
                <div className="footer__call-item">
                  <div className="footer__call-item-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.5032 3.48779C18.2471 1.23984 15.2467 0.00131836 12.0502 0C5.4635 0 0.102885 5.33481 0.100235 11.8917C0.0993524 13.9878 0.649544 16.0339 1.69532 17.8374L0 24L6.33485 22.3462C8.08036 23.2938 10.0455 23.7932 12.0453 23.7938H12.0503C18.6363 23.7938 23.9975 18.4585 24 11.9013C24.0013 8.72344 22.7595 5.7356 20.5032 3.48779ZM17.4982 14.3821C17.1997 14.2333 15.7317 13.5146 15.4579 13.4153C15.1844 13.3162 14.9851 13.2668 14.7863 13.5642C14.5871 13.8615 14.015 14.531 13.8407 14.7292C13.6665 14.9275 13.4925 14.9524 13.1938 14.8036C12.8952 14.6549 11.9332 14.341 10.7926 13.3286C9.90506 12.5407 9.30586 11.5676 9.13159 11.2702C8.95761 10.9726 9.13011 10.8272 9.26258 10.6638C9.58581 10.2643 9.90948 9.84551 10.009 9.64732C10.1086 9.44897 10.0587 9.27539 9.98396 9.12671C9.90948 8.97803 9.31233 7.51538 9.06358 6.92021C8.82102 6.34102 8.57507 6.41924 8.39167 6.41016C8.21769 6.40151 8.01855 6.39976 7.8194 6.39976C7.6204 6.39976 7.29688 6.47402 7.02311 6.77168C6.74948 7.06919 5.97822 7.78799 5.97822 9.25064C5.97822 10.7133 7.04813 12.1263 7.19738 12.3246C7.34663 12.5229 9.30291 15.5244 12.2981 16.8114C13.0104 17.1179 13.5665 17.3005 14.0003 17.4375C14.7156 17.6637 15.3664 17.6317 15.8809 17.5553C16.4547 17.4699 17.6473 16.8363 17.8964 16.1423C18.1451 15.4481 18.1451 14.8532 18.0704 14.7292C17.9959 14.6052 17.7967 14.531 17.4982 14.3821Z"
                        fill="#389E0D"
                      ></path>
                    </svg>
                  </div>
                  <div className="footer__call-item-content">
                    <p className="footer__call-item-text">Whatsapp</p>
                    <a
                      href="https://api.whatsapp.com/send?phone=996556613333"
                      target="_blank"
                      rel="noreferrer"
                      className="footer__call-item-number"
                    >
                      +996 (556) 61 33 33
                    </a>
                  </div>
                </div>

                <div className="footer__call-item">
                  <div className="footer__call-item-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 0C5.37253 0 0 5.37253 0 12C0 18.6275 5.37253 24 12 24C18.6275 24 24 18.6275 24 12C24 5.37253 18.6275 0 12 0Z"
                        fill="#40B3E0"
                      ></path>
                      <path
                        d="M17.8389 6.90284L15.6955 17.7093C15.6955 17.7093 15.3957 18.4587 14.5714 18.099L9.62533 14.307L7.82683 13.4378L4.79927 12.4185C4.79927 12.4185 4.33464 12.2537 4.28964 11.894C4.24464 11.5343 4.81427 11.3395 4.81427 11.3395L16.8495 6.61821C16.8495 6.61821 17.8388 6.18359 17.8388 6.90303"
                        fill="white"
                      ></path>
                      <path
                        d="M9.24461 17.5878C9.24461 17.5878 9.10024 17.5743 8.92024 17.0047C8.74052 16.4351 7.82617 13.4376 7.82617 13.4376L15.0954 8.82132C15.0954 8.82132 15.5151 8.56651 15.5001 8.82132C15.5001 8.82132 15.575 8.86632 15.3501 9.07613C15.1254 9.28604 9.63977 14.217 9.63977 14.217"
                        fill="#D2E5F1"
                      ></path>
                      <path
                        d="M11.5226 15.7608L9.56634 17.5445C9.56634 17.5445 9.41334 17.6605 9.24609 17.5878L9.62072 14.2747"
                        fill="#B5CFE4"
                      ></path>
                    </svg>
                  </div>
                  <div className="footer__call-item-content">
                    <p className="footer__call-item-text">Telegram</p>
                    <a
                      href="https://t.me/cbkkg_bot"
                      target="_blank"
                      rel="noreferrer"
                      className="footer__call-item-number"
                    >
                      cbkkg_bot
                    </a>
                  </div>
                </div>

                <div className="footer__call-item">
                  <div className="footer__call-item-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="24"
                        height="24"
                        rx="12"
                        fill="#0092A1"
                      ></rect>
                      <path
                        d="M11.1134 12.8849C12.2531 14.0239 13.5736 15.114 14.0956 14.592C14.8422 13.8454 15.303 13.1945 16.9504 14.5186C18.597 15.8419 17.332 16.7246 16.6084 17.4475C15.7732 18.2827 12.6599 17.4922 9.58263 14.4156C6.50607 11.3383 5.71767 8.22503 6.55359 7.38983C7.27719 6.66551 8.15559 5.40119 9.47895 7.04783C10.803 8.69447 10.1529 9.15527 9.40479 9.90263C8.88495 10.4246 9.97431 11.7451 11.1134 12.8849Z"
                        fill="white"
                      ></path>
                    </svg>
                  </div>
                  <div className="footer__call-item-content">
                    <p className="footer__call-item-text">Phone</p>
                    <a
                      href="tel:+996312613333"
                      className="footer__call-item-number"
                    >
                      +996 (312) 61 33 33
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer__item footer__item--address">
              <div className="footer__address-column">
                <div className="footer__address-item">
                  <div className="footer__address-item-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="24"
                        height="24"
                        rx="12"
                        fill="#0092A1"
                      ></rect>
                      <g clipPath="url(#clip0_6126_12489)">
                        <path
                          d="M16.7639 7.31162C15.709 5.70417 13.9676 4.79999 12.0086 4.79999C10.0663 4.79999 8.32486 5.70417 7.23649 7.31162C6.14812 8.88557 5.89695 10.8949 6.56672 12.653C6.75091 13.1218 7.03556 13.6074 7.40393 14.0428L11.6402 19.0325C11.7407 19.133 11.8411 19.2 11.9918 19.2C12.1425 19.2 12.243 19.133 12.3435 19.0325L16.5965 14.0428C16.9649 13.6074 17.2663 13.1386 17.4337 12.653C18.1035 10.8949 17.8523 8.88557 16.7639 7.31162ZM12.0086 13.2391C10.5686 13.2391 9.37974 12.0502 9.37974 10.6102C9.37974 9.17022 10.5686 7.98138 12.0086 7.98138C13.4486 7.98138 14.6374 9.17022 14.6374 10.6102C14.6374 12.0502 13.4653 13.2391 12.0086 13.2391Z"
                          fill="white"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_6126_12489">
                          <rect
                            width="14.4"
                            height="14.4"
                            fill="white"
                            transform="translate(4.80078 4.79999)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="footer__address-item-content">
                    <p className="footer__address-item-title">Main office</p>
                    <a
                      href="https://mbank.kg/maps"
                      className="footer__address-item-text"
                    >
                      ул. Тоголок Молдо, 54а Бишкек, Кыргызстан, 720033
                    </a>
                  </div>
                </div>

                <div className="footer__address-item">
                  <div className="footer__address-item-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="24"
                        height="24"
                        rx="12"
                        fill="#0092A1"
                      ></rect>
                      <g clipPath="url(#clip0_6126_12499)">
                        <path
                          d="M12.4254 13.8145C12.2903 13.8917 12.1359 13.9303 12.0008 13.9303C11.8657 13.9303 11.7112 13.8917 11.5761 13.8145L4.80078 9.68365V15.185C4.80078 16.3625 5.74663 17.3083 6.92411 17.3083H17.0775C18.2549 17.3083 19.2008 16.3625 19.2008 15.185V9.68365L12.4254 13.8145Z"
                          fill="white"
                        ></path>
                        <path
                          d="M17.0781 6.69165H6.92479C5.92104 6.69165 5.07171 7.40586 4.85938 8.3517L12.0015 12.7142L19.1436 8.3517C18.9312 7.40586 18.0819 6.69165 17.0781 6.69165Z"
                          fill="white"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_6126_12499">
                          <rect
                            width="14.4"
                            height="14.4"
                            fill="white"
                            transform="translate(4.80078 4.79999)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div className="footer__address-item-content">
                    <div className="footer__address-item-title">
                      Commercial offer
                    </div>
                    <div className="footer__address-item-text">
                      <a href="mailto:marketing@cbk.kg">marketing@cbk.kg</a>
                    </div>
                  </div>
                </div>

                <div className="footer__address-item">
                  <div className="footer__address-item-icon"></div>
                  <div className="footer__address-item-content">
                    <div className="footer__address-item-title">
                      Reviews and wishes
                    </div>
                    <div className="footer__address-item-text">
                      <a href="mailto:otzyv@cbk.kg">otzyv@cbk.kg</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="footer__bottom-copywrite">
              © 2006-2024, OJSC «Commercial bank KYRGYZSTAN». Licence #014 NBKR
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
